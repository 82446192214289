export const FirstData = [
    {
        image: "./images/one.png",
    },
    {
        image: "./images/two.png",
    },
    {
        image: "./images/three.png",
    },
    {
        image: "./images/four.png",
    },
    {
        image: "./images/five.png",
    },
    {
        image: "./images/six.png",
    },
    {
        image: "./images/seven.png",
    },
    {
        image: "./images/eight.png",
    },
    {
        image: "./images/nine.png",
    },
    {
        image: "./images/ten.png",
    },
    {
        image: "./images/eleven.png",
    },
    {
        image: "./images/twelve.png",
    },
]