export const SecondtData = [
    {
        image: "./images/first.png",
    },
    {
        image: "./images/second.png",
    },
    {
        image: "./images/third.png",
    },
    {
        image: "./images/fourth.png",
    },
    {
        image: "./images/fifth.png",
    },
    {
        image: "./images/sixth.png",
    },
    {
        image: "./images/seventh.png",
    },
    {
        image: "./images/eighth.png",
    },
    {
        image: "./images/ninth.png",
    },
    {
        image: "./images/tenth.png",
    },
    {
        image: "./images/eleventh.png",
    },
    {
        image: "./images/twelveth.png",
    },
    {
        image: "./images/thirteenth.png",
    },
    {
        image: "./images/fourteenth.png",
    },
    {
        image: "./images/fifteenth.png",
    },
    {
        image: "./images/sixteenth.png",
    }
]